.wrapper {
    width: 600px;
    margin: 0 auto;
  }
  
  .rang {
    text-align: center;
  }
  
  .rang-title {
    display: inline-block;
    width: 200px;
  }
  
  .meter {
    display: flex;
    width: 70px;
    height: 40px;
    margin: 0 auto;
  }
  
  .meter-left {
    -webkit-transform: skewX(-2deg) skewY(1deg);
            transform: skewX(-2deg) skewY(1deg);
    stroke-width: 28;
    stroke-dashoffset: 213;
    stroke-dasharray: 100 380;
    stroke: #41BE64;
    fill: none;
  }
  
  .meter-center {
    stroke-width: 26;
    stroke-dashoffset: 110;
    stroke-dasharray: 100 380;
    stroke: #FFC966;
    fill: none;
  }
  
  .meter-right {
    -webkit-transform: skewX(2deg) skewY(1deg);
            transform: skewX(2deg) skewY(1deg);
    stroke-width: 28;
    stroke-dashoffset: 8;
    stroke-dasharray: 100 380;
    stroke: #F14668;
    fill: none;
  }
  
  .meter-clock {
    -webkit-transform: rotate(-54deg);
            transform: rotate(-54deg);
    -webkit-transform-origin: 137px 146px;
            transform-origin: 137px 146px;
    fill: black;
  }
  
  .meter-circle {
    fill: black;
  }
  