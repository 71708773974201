.is-overlay {
  @extend %overlay;
}

.is-relative {
  position: relative !important;
}

.is-absolute {
  position: absolute !important;
}

.is-vcentered {
  display: flex;
  align-items: center !important;
}

.is-fullwidth {
  width: 100% !important;
}

.is-fullheight {
  height: 100% !important;
}

.is-justify-center {
  justify-content: center !important;
}

.is-justify-between {
  justify-content: space-between !important;
}

.is-justify-end {
  justify-content: flex-end !important;
}

.is-justify-start {
  justify-content: flex-start !important;
}

.is-justify-around {
  justify-content: space-around !important;
}

.is-justify-evenly {
  justify-content: space-evenly !important;
}
