
/** =================== IBM Plex Sans =================== **/

/** IBMPlexSans Thin **/
@font-face {
  font-family: "IBMPlexSans";
  font-weight: 100;
  font-style: normal;
  src: url("IBMPlexSans-Thin.ttf") format("truetype");
}

/** IBMPlexSans Thin Italic **/
@font-face {
  font-family: "IBMPlexSans";
  font-weight: 100;
  font-style: italic;
  src: url("IBMPlexSans-ThinItalic.ttf") format("truetype");
}

/** IBMPlexSans Light **/
@font-face {
  font-family: "IBMPlexSans";
  font-weight: 300;
  font-style: normal;
  src: url("IBMPlexSans-Light.ttf") format("truetype");
}

/** IBMPlexSans Regular **/
@font-face {
  font-family: "IBMPlexSans";
  font-weight: 400;
  font-style: normal;
  src: url("IBMPlexSans-Regular.ttf") format("truetype");
}

/** IBMPlexSans Medium **/
@font-face {
  font-family: "IBMPlexSans";
  font-weight: 500;
  font-style: normal;
  src: url("IBMPlexSans-Medium.ttf") format("truetype");
}

/** IBMPlexSans SemiBold **/
@font-face {
  font-family: "IBMPlexSans";
  font-weight: 600;
  font-style: normal;
  src: url("IBMPlexSans-SemiBold.ttf") format("truetype");
}

/** IBMPlexSans Bold **/
@font-face {
  font-family: "IBMPlexSans";
  font-weight: 700;
  font-style: normal;
  src: url("IBMPlexSans-Bold.ttf") format("truetype");
}

/** IBMPlexSans Italic **/
@font-face {
  font-family: "IBMPlexSans";
  font-weight: 400;
  font-style: italic;
  src: url("IBMPlexSans-Italic.ttf") format("truetype");
}
