.is-radiusless {
  border-radius: 0 !important;
}

.is-shadowless {
  box-shadow: none !important;
}

.is-unselectable {
  @extend %unselectable;
}

.is-borderless{
  border: none !important;
}