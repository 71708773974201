/** =================== Neo Tech Mono =================== **/

/** IBMPlexMono Thin **/

@font-face {
    font-family: "NeoTechAlt";
    font-weight: 100;
    font-style: normal;
    src: url("NeoTechAlt.ttf") format("truetype");
}
@font-face {
    font-family: "NeoTechLight";
    font-weight: 300;
    font-style: normal;
    src: url("NeoTechAlt-Light.ttf") format("truetype");
}
@font-face {
    font-family: "NeoTechAltMedium";
    font-weight: 400;
    font-style: normal;
    src: url("NeoTechAlt-Medium.ttf") format("truetype");
}