@import '../../assets/fonts/typography.scss';

// Colors

$black: hsl(0, 0%, 14%) !default; // dark-mode background
$black-bis: hsl(0, 0%, 17%) !default; // dark-mode foreground
$black-ter: hsl(0, 0%, 27%) !default; // light-mode text color

$grey-darker: hsl(235, 5%, 44%) !default; // grey-darker: dark-mode
$grey-dark: hsl(0, 0%, 90%) !default; // grey: dark-mode
$grey: hsl(240, 5%, 92%) !default; // grey:light-mode
$grey-light: hsl(0, 0%, 71%) !default; // none
$grey-lighter: hsl(0, 0%, 86%) !default; // none
$grey-lightest: hsl(0, 0%, 93%) !default; // none

$white-ter: hsl(0, 0%, 96%) !default; // none
$white-bis: hsl(240, 100%, 99%) !default; // light-mode background
$white: hsl(0, 0%, 100%) !default;

$orange: hsl(14, 100%, 53%) !default; // state
$yellow: hsl(47, 100%, 50%) !default; // warning
$green: hsl(111, 100%, 70%) !default; // success
$turquoise: hsl(171, 100%, 41%) !default; // state
$cyan: hsl(204, 71%, 53%) !default; // state
$blue: hsl(157, 74%, 13%) !default; // light-mode primary color
//$blue: hsl(197, 100%, 50%) !default;
//$blue: hsl(95, 63%, 42%) !default;
//$blue: hsl(219, 84%, 56%) !default; // light-mode primary color
$purple: hsl(234, 37%, 49%) !default; // dark-mode primary color
$red: hsl(0, 96%, 46%) !default; // danger
$lavender: hsl(240, 100%, 95%) !default; // light-mode color
// Typography

/*
$family-sans-serif: "NeoTechLight", "sans-serif" !default;
$family-sans-serif: "NeoTechAltMedium", "sans-serif" !default;
$family-sans-serif: "NeoTechAlt", "sans-serif" !default;*/

$family-sans-serif: "NeoTechAlt", "sans-serif" !default;
$family-secondary : "NeoTechLight" , "secondary" !default;
$family-monospace:  "NeoTechAltMedium" , "monospace" !default;
$render-mode: optimizeLegibility !default;

$size-1: 3rem !default;
$size-2: 2.5rem !default;
$size-3: 2rem !default;
$size-4: 1.5rem !default;
$size-5: 1.25rem !default;
$size-6: 1rem !default;
$size-7: 0.875rem !default;

$weight-light: 300 !default;
$weight-normal: 400 !default;
$weight-medium: 500 !default;
$weight-semibold: 600 !default;
$weight-bold: 700 !default;

// Spacing

$block-spacing: 1.5rem !default;

// Responsiveness

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px !default;

// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 768px !default;

// 960px container + 4rem
$desktop: 960px + 2 * $gap !default;

// 1152px container + 4rem
$widescreen: 1152px + 2 * $gap !default;
$widescreen-enabled: true !default;

// 1344px container + 4rem
$fullhd: 1344px + 2 * $gap !default;
$fullhd-enabled: true !default;

// Miscellaneous

$easing: ease-out !default;
$radius-small: 2px !default;
$radius: 4px !default;
$radius-large: 6px !default;
$radius-rounded: 290486px !default;
$speed: 86ms !default;

// Flags

$variable-columns: true !default;
$rtl: false !default;
