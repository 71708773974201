/** =================== IBM Plex Mono =================== **/

/** IBMPlexMono Thin **/
@font-face {
  font-family: "IBMPlexMono";
  font-weight: 100;
  font-style: normal;
  src: url("IBMPlexMono-Thin.ttf") format("truetype");
}

/** IBMPlexMono Light **/
@font-face {
  font-family: "IBMPlexMono";
  font-weight: 300;
  font-style: normal;
  src: url("IBMPlexMono-Light.ttf") format("truetype");
}

/** IBMPlexMono Regular **/
@font-face {
  font-family: "IBMPlexMono";
  font-weight: 400;
  font-style: normal;
  src: url("IBMPlexMono-Regular.ttf") format("truetype");
}

/** IBMPlexMono Medium **/
@font-face {
  font-family: "IBMPlexMono";
  font-weight: 500;
  font-style: normal;
  src: url("IBMPlexMono-Medium.ttf") format("truetype");
}

/** IBMPlexMono SemiBold **/
@font-face {
  font-family: "IBMPlexMono";
  font-weight: 600;
  font-style: normal;
  src: url("IBMPlexMono-SemiBold.ttf") format("truetype");
}

/** IBMPlexMono Bold **/
@font-face {
  font-family: "IBMPlexMono";
  font-weight: 700;
  font-style: normal;
  src: url("IBMPlexMono-Bold.ttf") format("truetype");
}

